<template>
  <div class="course-container">
    <div class="course-container-title">我的课程（{{courseList.length}}）</div>
    <div v-if="courseList && courseList.length > 0">
      <div class="course-list" >
        <div
          v-for="(item, index) in courseList" :key="index"
          class="course-item"
        >
          <div class="course-item-img">
            <img :src="`/_fid/` + item.cover" alt="">
            <div class="course-item-img-none">
              <span>课程总时长：{{ item.joinWareDurationSum }}</span>
              <span>已学习时长：{{item.studyWareDurationSum}}</span>
              <!-- <span>课程总学分：88</span>
              <span>已获得学分：33</span> -->
            </div>
          </div>
          <div class="course-item-box">
            <div class="course-item-title">{{item.courseName}}</div>
            <div class="course-item-progress-c">
              <a-progress :percent="item.process/100" :stroke-width="6" color="#4F17A8" track-color="#f5f5f5">
                <template v-slot:text="scope" >
                  <div style="width: 80px; text-align: left; color: #4F17A8; font-size: 14px;">已学习 
                    {{ parseInt((scope.percent == null ? 0: scope.percent)*100  + '') }}
                    %</div>
                </template>
              </a-progress>
            </div>
            <div class="course-item-progress-button">
              <div v-if="item.status == 1 && item.studyState == 1" class="btn btn1" style="background-color: #4F17A8;" @click="handClick(item)">继续学习</div>
              <!-- <div class="btn btn2" style="margin-left: 10px;">申请结业</div> -->
            </div>
          </div>
          <div v-if="item.status == 0" class="course-item-timer">已下架</div>
        </div>
      </div>
      <div style="width: 100%; margin: 30px 0; display: flex; justify-content: flex-end;">
        <a-pagination
        :default-current="current"
        :page-item-style="{ 'border': '1px solid rgba(0,0,0,0.1500)', 'border-radius': '2px' }"
        :active-page-item-style="{ 'background-color': 'transparent', 'border': '1px solid #4F17A8','color': '#4F17A8'}"
        :total="total"
        :default-page-size="size"
        @change="change" />
      </div>
    </div>
    <div v-else ><a-empty /></div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { ratePage }  from '@/api/course';
import { useRouter } from 'vue-router';
import { formatSeconds } from '@/utils/util';
export default {
  setup () {
    const state = reactive({
      total: 0,
      courseList: [],
      current: 1,
      size: 9,
    })
    const getList = (current, size) => {
      ratePage({current, size}).then(res => {
        const list = res.result.records;
        list.forEach((item, index) => {
          item.joinWareDurationSum = formatSeconds(item.joinWareDurationSum);
          item.studyWareDurationSum = formatSeconds(item.studyWareDurationSum);
        });
        state.courseList = list;
        state.current = res.result.current;
        state.total = res.result.total;
      })
    };
    const router = useRouter ()
    const handClick = (v) => {
        router.push({ 
            path: '/account/courseware',
            query:{
                courseId:v.courseId,
                classId:v.classId,
            }
        })
        sessionStorage.setItem('courseInfo',JSON.stringify(v))
    };
    getList(1,9);
    const change = (val) => {
      console.log('第几页' + val)
      getList(val,9)
    }
    return {
      ...toRefs(state),
      change,
      getList,
      handClick,
    }
  }
}
</script>

<style lang="less" >

</style>

<style lang="less" scoped>
.course-container {
  width: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #fff;
  .course-container-title {
    color: #323233;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}

.course-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .course-item {
    width: 32%;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.1);
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;
    .course-item-img:hover {
      .course-item-img-none {
        transition: top 0.3s;
        top: 0;
      }
    }
    .course-item-img {
      cursor: pointer;
      position: relative;
      width: 100%;
      height: 160px;
      .course-item-img-none {
        transition: top 0.3s;
        position: absolute;
        left: 0;
        top: -160px;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        border-radius: 4px 4px 0 0;
        display: flex;
        flex-flow: column;
        padding-left: 50px;
        box-sizing: border-box;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        span {
          margin-top: 12px;
        }
      }
      img {
        display: block;
        width: 100%;
        height: 160px;
        border-radius: 4px 4px 0 0;
      }
    }
    .course-item-box {
      padding: 10px;
      box-sizing: border-box;
      .course-item-title {
        color: #333333;
        font-size: 16px;
        margin-bottom: 6px;
      }
      .course-item-progress {
        margin-top: 6px;;
        display: flex;
        align-items: center;
        .course-item-lable {
          flex: 1;
        }
      }
      .course-item-progress-button {
        margin-top: 8px;
        height: 24px;
        display: flex;
        justify-content: flex-end;
      }
    }
    .course-item-timer {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0,0,0,0.2);
      font-size: 20px;
      text-align: right;
      padding: 20px;
      color: #949191;
    }
  }
}
.course-list::after {
  content: "";
  width: 32%;
}
.btn {
  width: 72px;
  height: 24px;
  border-radius: 2px;
  font-size: 14px;
}
</style>
